/**
 * Alert functions. Code to show alert and choice dialogs, as well as notifications (when migrated).
 * Created by andrew.stalker on 23/10/2018.
 */

/* globals popup, config, detachFixedFocus, attachInputEvents, alert, createKeyAction, validateUser, attachFixedFocus,
 getProgramType, playAlertSound, attachMenuNumbers */

/**
 * @description Shows an alert popup on the screen.
 * @param {String} message
 * @param {AlertOptions=} options
 */
function showAlert(message,options) {
	"use strict";
	detachFixedFocus();
	//set up options
	var focus,
		callback = null,
		canClose,
		modalType,
		type = "error",
		auth = false,
		authUser = "",
		authKey = "adminLevel",
		canCancel = false,
		cancelText = "Cancel",
		cancelCallback = null,
		confirmText = "OK",
		approvalRequired = false,
		closeButtonElement = $("#alertCloseButton"),
		authSectionElement = $("#alertModalAuthSection"),
		cancelButtonElement = $("#alertCancelButton"),
		confirmButton = $("#alertConfirmButton"),
		usernameElement = $("#alertUsername"),
		closeOrCancelAlertHandler = function() {

			//Hide the modal and update globals
			$("#alertModal").modal('hide');
			popup.modalsOpen --;

			$("#alertPassword").val("");
			usernameElement.val("");

			//Refocus and fix if possible
			//todo this could be tidied up, as the fixed focusing is duplicated
			if (focus !== undefined) {
				var focusElement = "#" + focus;
				$(focusElement).focus();
				//These checks for focusing are also dependant on what the focus element was.
				//We may be in the program and need to focus on something else before being able to scan etc.
				//If that is the case then attachFixedFocus will be called later in program, so we only want to focus.
				if(popup.modalsOpen === 0) {
					if ($('#locationInput').length && focusElement === '#locationInput') {
						attachFixedFocus("#locationInput");
					} else if ($('#scanInput').length && focusElement === '#scanInput') {
						attachFixedFocus("#scanInput");
					} else if ($('#scanInput').length && focusElement === '#scanInput') {
						attachFixedFocus("#scanInput");
					} else if (location.pathname.substring(1) === "dsmenu.php") {
						attachMenuNumbers();
					}
				}
			} else {
				if(popup.modalsOpen === 0) {
					if ($('#locationInput').length) {
						attachFixedFocus("#locationInput");
					} else if ($('#scanInput').length) {
						attachFixedFocus("#scanInput");
					} else if ($('#scanInput').length) {
						attachFixedFocus("#scanInput");
					} else if (location.pathname.substring(1) === "dsmenu.php") {
						attachMenuNumbers();
					}
				}
			}

			if (getProgramType() !== "") {
				if(popup.modalsOpen === 0) {
					attachFixedFocus("#scanInput");
				}
			}

			//Clear the class - this might be unnecessary as it is reset on parent call
			$("#alertModalContent").removeClass(modalType);
		};

	if(typeof options !== "undefined") {
		type = (options.type)? options.type:"error";

		if(typeof options.auth !== 'undefined') {
			auth = options.auth;
		}
		if(typeof options.authKey !== 'undefined') {
			authKey = options.authKey;
		}
		if(typeof options.focus !== 'undefined') {
			focus = options.focus;
		}
		if(typeof options.callback === 'function') {
			callback = options.callback;
		}
		if(typeof options.canClose !== 'undefined') {
			canClose = options.canClose;
		}
		if(typeof options.authUser !== 'undefined') {
			authUser = options.authUser;
		}
		if(typeof options.approvalRequired !== 'undefined') {
			approvalRequired = options.approvalRequired;
		}
		if(typeof options.canCancel !== 'undefined') {
			canCancel = options.canCancel;
		}
		if(typeof options.cancelText !== 'undefined') {
			cancelText = options.cancelText;
		}
		if(typeof options.cancelCallback !== 'undefined') {
			cancelCallback = options.cancelCallback;
		}
		if(typeof options.confirmText !== 'undefined') {
			confirmText = options.confirmText;
		}
	}
	switch(type){
		case "error":
			modalType = "panel-danger";
			break;
		case "success":
			modalType = "panel-success";
			break;
		case "info":
			modalType = "panel-info";
			break;
	}

	//1. Set the modal type
	$("#alertModalContent").addClass(modalType);

	//2. If can close then show the button to close
	if (canClose) closeButtonElement.show(); else {
		closeButtonElement.hide();
	}

	//3. Set the title
	$("#alertModalTitle").html(type.capitalize());

	//4. Set the message
	$("#alertModalMessage").html(message);

	//5. IF auth then show the auth section
	if (auth) {
		authSectionElement.show();
	} else {
		authSectionElement.hide();
	}

	//6. If canCancel then show the cancel button
	if(canCancel) {
		cancelButtonElement.show().html(cancelText);
	} else {
		cancelButtonElement.hide();
	}

	//7. Override the OK button text.
	confirmButton.html(confirmText);

	//8. Show on the page
	popup.modalsOpen ++;
	$("#alertModal").modal({backdrop:'static', keyboard:false, show:true});

	//play sound if possible
	playAlertSound(type);
	attachInputEvents();

	//Authentication checks
	if(auth) {
		usernameElement.focus();
		createKeyAction("#alertUsername","#alertPassword");
		//createCompleteAction("#alertPassword","#alertConfirm");
	} else {
		confirmButton.focus();
	}

	confirmButton.off("click").on("click", function() {
		var result = 0;
		if (auth) {
			var username = usernameElement.val();
			//validate user and entered password. Also check that they're an admin (seclev > adminlevel)
			//If incident approval is on then the username cannot match incident record passed through.
			if (approvalRequired) {
				if (username === authUser) {
					// Username matches user passed to error dialog. These cannot match.
					alert("This user cannot authorise. Approval requires another admin user.");
					usernameElement.focus();
					if(callback) {
						callback(false);
					}
					return;
				}
			}
			result = validateUser(username, $("#alertPassword").val(), authKey);
			if (typeof result === "string") {
				alert(result);
				$("#alertUsername").focus();
				if(callback) {
					callback(false);
				}
				return;
			}
		}
		closeOrCancelAlertHandler();

		if(callback) {
			callback(true, result);
		}

	}).on("keypress", function (e) {
		if (!e) e = window.event;
		e.preventDefault();
	});

	closeButtonElement.on("click", function() {
		closeOrCancelAlertHandler();
	});

	cancelButtonElement.on("click", function() {
		closeOrCancelAlertHandler();
		// If a cancel callback was passed run it now. User pressed the cancel button
		if (cancelCallback) {
			cancelCallback();
		}
	});
}

/**
 * @typedef {object} AlertOptions
 * @description Optional options which change the behaviour of the Alert.
 * @property {boolean|undefined} auth - True if supervisor authentication is required.
 * @property {string|undefined} authKey - The field level key to use on an authorisation prompt
 * @property {function|undefined} callback - A function to run once 'OK' has been pressed.
 * @property {boolean|undefined} canClose - True if the alert can be closed rather than pressing 'OK'.
 * @property {string|undefined} focus - The name of an element to focus to after the 'OK' has been pressed.
 * @property {string|undefined} type - Type of alert, setting the colour and sound. Default 'error'. error, success, info.
 * @property {string|undefined} authUser - The username raising original alert box (not necessarily the user logged in)
 * @property {boolean|undefined} canCancel - Allows the cancel button to be shown, forcing a callback(false) or false return.
 * @property {boolean|undefined} approvalRequired - If incident approval trigger requires user name to mismatch.
 * @property {string|undefined} cancelText - Text to display in the cancel button. Default is 'Cancel'
 * @property {function|undefined} cancelCallback - A function to run once the cancel or close button is pressed.
 * @property {string|undefined} confirmText - Optional text to display on the 'OK' button rather than OK.
 */